<template>
  <dash-page-new
      :title="$t('Westudypartner')"
      :subtitle="$t('WestudyPartner')"
      :root="$t('Dashboard')"
  >

    <template #default>
      <router-view></router-view>
    </template>

  </dash-page-new>

</template>

<script>
export default {
  name: "DashWestudyPartner"
}
</script>

<style scoped>

</style>